import './Prompt.css';

const Prompt = ({ chat, profileComponent, chooseLogo }) => {
    return (
        <div className='prompt message-parent'>
            {profileComponent}
            <div className='prompt-text'>{chat.data.content}</div>
            {chooseLogo(chat.source_id)}
        </div>
    );
}

export default Prompt;