import './SelectOptions.css';

import { forwardRef, useState } from 'react';
import Select from '@mui/base/Select';
import Option from '@mui/base/Option';
import { styled } from '@mui/system';

import { UnfoldMoreRounded } from '@mui/icons-material';

const CustomSelect = forwardRef(function CustomSelect(props, ref) {

    const { onChange, ...otherProps } = props;

    const handleOnChange = (event, selectedOption) => {
        if (selectedOption !== null) {
          onChange(event, selectedOption);
        }
      };

    const slots = {
        root: StyledButton,
        listbox: StyledListbox,
        ...otherProps.slots,
    };

    return <Select {...otherProps} ref={ref} slots={slots} onChange={handleOnChange}/>;
});

const defaultButton = forwardRef((props, ref) => {
    const { ownerState, field = {}, ...other } = props;
    return (
        <button type="button" {...other} ref={ref}>
            {field && field.icon ? field.icon : ""}
            {field && field.text ? field.text : ""}
            <UnfoldMoreRounded className='unfold-more'/>
        </button>
    );
});

const StyledButton = styled(defaultButton, { shouldForwardProp: () => true })`
    box-sizing: border-box;
    text-align: left;
    width: 250px;
    background-color: var(--search-bar-color);
    border: 1px solid var(--color-blue-4);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    font-size: 14px;
    &:focus {
        background-color: var(--color-blue-1);
    }
    transition: var(--transition-time);
    padding: 7px;
    color: var(--message-text-color);
`

const StyledListbox = styled('ul')`
    box-sizing: border-box;
    background-color: var(--search-bar-color);
    padding: 0px;
    outline: 0;
    width: 250px;
    border-radius: 8px;
    color: var(--message-text-color);
` 

const StyledOption = styled(Option)`
    list-style: none;
    padding: 10px;
    border-radius: 8px;
    &:hover {
        background-color: var(--color-blue-1);
    }
    display: flex;
    gap: 5px;
    cursor: pointer;
    font-size: 14px;
    color: var(--message-text-color);
`

const SelectOptions = ({ options, currentSelect, setCurrentSelect }) => {

    return (
        <CustomSelect value={currentSelect} field={currentSelect} onChange={(event, selectedOption) => setCurrentSelect(selectedOption)}>
            {options.map(option => (
                <StyledOption key={option.id} value={option}>
                    {option.icon !== null ? option.icon : ""}
                    {option.text !== null ? option.text : ""}
                </StyledOption>
            ))}
        </CustomSelect>
    )
}

export default SelectOptions;