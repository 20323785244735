
import Edit from './Edit'
import View from './View'

import EditIcon from '@mui/icons-material/Edit';

import { useContext, useEffect, useState } from 'react';

import { updateAccountID } from '../../../logic/meta';

import { ServerInfo } from '../../../App';

const Meta = ({ editMode, setEditMode, userDetails, setUserDetails, setModel, setReconnectError }) => {
    const [ accountID, setAccountID ] = useState("");
    const [ loadingSave, setLoadingSave ] = useState(false);
    const { userID, accessToken } = useContext(ServerInfo);

    useEffect(() => {
        setAccountID(userDetails.meta_account_id)
    }, [userDetails]);

    const containsOnlyNumbers = (inputString) => {
        // Use regular expression to check if the inputString contains only numeric characters
        return /^\d+$/.test(inputString);
    }

    const handleSaveChanges = async () => {
        setLoadingSave(true);
        if (containsOnlyNumbers(accountID)) {
            await updateAccountID(userID, accountID, setUserDetails, setEditMode, setModel, accessToken);
        } else {
            setModel({show: true, message: "Account ID contains only numbers", type: "warning"});
        }
        
        setLoadingSave(false);
    }
    

    const selectButtonSet = () => {
        if (editMode) {
            return (
                <div className='edit-mode'>
                    <button className='edit-google' onClick={() => setEditMode(false)}>Cancel</button>
                    <button className='save-changes' onClick={() => handleSaveChanges()}>Save Changes</button>
                </div>
            )
        } else {
            return (
                <button className='edit-google' onClick={() => setEditMode(true)}>
                    <EditIcon sx={{color: 'var(--color-blue-5)'}}/>
                    Edit
                </button>
            )
        }
    }

    return (
        <div className='content-semi'>
            <div className='settings-semi-title google-header'>
                Facebook Ads
                {userDetails.connected_meta ? selectButtonSet() : ""}
            </div>
            {editMode ? 
            <Edit 
                userDetails={userDetails} 
                setAccountID={setAccountID}
                loadingSave={loadingSave}
            /> 
            : 
            <View 
                userDetails={userDetails}
                setReconnectError={setReconnectError}
                setModel={setModel} 
                setUserDetails={setUserDetails}
            />}
        </div>
    )
}

export default Meta