
import Edit from './Edit'
import View from './View'

import EditIcon from '@mui/icons-material/Edit';

import { useEffect, useState } from 'react';

import { updateTikTokAdId } from '../../../logic/tiktok'
import { useContext } from 'react';
import { ServerInfo } from '../../../App';


const Meta = ({ editMode, setEditMode, userDetails, setUserDetails, setModel, setReconnectError }) => {

    const { userID, accessToken } = useContext(ServerInfo);

    const [ adID, setAdID ] = useState("");
    const [ loadingSave, setLoadingSave ] = useState(false);

    useEffect(() => {
        setAdID(userDetails.tiktok_ad_id)
    }, [userDetails]);

    const containsOnlyNumbers = (inputString) => {
        // Use regular expression to check if the inputString contains only numeric characters
        return /^\d+$/.test(inputString);
    }

    const handleSaveChanges = async () => {
        setLoadingSave(true);
        if (containsOnlyNumbers(adID)) {
            await updateTikTokAdId(userID, adID, setUserDetails, setEditMode, setModel, accessToken);
        } else {
            setModel({show: true, message: "Advertiser ID contains only numbers", type: "warning"});
        }
        
        setLoadingSave(false);
    }
    

    const selectButtonSet = () => {
        if (editMode) {
            return (
                <div className='edit-mode'>
                    <button className='edit-google' onClick={() => setEditMode(false)}>Cancel</button>
                    <button className='save-changes' onClick={() => handleSaveChanges()}>Save Changes</button>
                </div>
            )
        } else {
            return (
                <button className='edit-google' onClick={() => setEditMode(true)}>
                    <EditIcon sx={{color: 'var(--color-blue-5)'}}/>
                    Edit
                </button>
            )
        }
    }

    return (
        <div className='content-semi'>
            <div className='settings-semi-title google-header'>
                TikTok Ads
                {userDetails.connected_tiktok ? selectButtonSet() : ""}
            </div>
            {editMode ? 
            <Edit 
                userDetails={userDetails} 
                setAdID={setAdID}
                loadingSave={loadingSave}
            /> 
            : 
            <View
                userDetails={userDetails} 
                setReconnectError={setReconnectError}
                setModel={setModel}
                setUserDetails={setUserDetails}
            />}
        </div>
    )
}

export default Meta