import './Google.css'

import EditIcon from '@mui/icons-material/Edit';

import Edit from './Edit';
import View from './View';

import { useState, useEffect, useContext } from 'react';

import { updatePropertyID } from '../../../logic/google_mec';

import { ServerInfo } from '../../../App';

const Google = ({ userDetails, setUserDetails, editMode, setEditMode, setModel, setReconnectError }) => {
    const { userID, accessToken } = useContext(ServerInfo);

    const [ propertyID, setPropertyID ] = useState("");
    const [ loadingSave, setLoadingSave ] = useState(false);
    
    useEffect(() => {
        setPropertyID(userDetails.property_id)
    }, [userDetails]);

    const containsOnlyNumbers = (inputString) => {
        // Use regular expression to check if the inputString contains only numeric characters
        return /^\d+$/.test(inputString);
    }

    const handleSaveChanges = async () => {
        setLoadingSave(true);
        let processedPropertyID = propertyID.replace(" ", "");
        if (containsOnlyNumbers(processedPropertyID)) {
            await updatePropertyID(userID, processedPropertyID, setUserDetails, setEditMode, setModel, accessToken);
        } else {
            setModel({show: true, message: "Property ID contains only numbers", type: "warning"});
        }
        setLoadingSave(false);
    }

    const selectButtonSet = () => {
        if (editMode) {
            return (
                <div className='edit-mode'>
                    <button className='edit-google' onClick={() => setEditMode(false)}>Cancel</button>
                    <button className='save-changes' onClick={() => handleSaveChanges()}>Save Changes</button>
                </div>
            )
        } else {
            return (
                <button className='edit-google' onClick={() => setEditMode(true)}>
                    <EditIcon sx={{color: 'var(--color-blue-5)'}}/>
                    Edit
                </button>
            )
        }
    }

    return (
        <div className='content-semi'>

            <div className='settings-semi-title google-header'>
                Google Analytics
                {userDetails.connected_ga ? selectButtonSet() : ""}
            </div>
            {editMode ? 
            <Edit 
                userDetails={userDetails}
                propertyID={propertyID} 
                setPropertyID={setPropertyID} 
                loadingSave={loadingSave}
            /> 
            : 
            <View 
                userDetails={userDetails}
                setUserDetails={setUserDetails} 
                setModel={setModel}
                setReconnectError={setReconnectError}
            />}
        </div>
    )

}

export default Google