import './TokenSummary.css';

const TokenSummary = ({remaining}) => {
    return (
        <div className='token-summary-inner'>
            <p>Tokens Summary</p>
            <div className='remaining'>
                <div className='item-1'>{remaining} remaining</div>
                {/* <div className='item-2'>Free Trial</div> */}
            </div>
        </div>
    )
}

export default TokenSummary;