import './Messages.css';

import Message from './Independent/Message';

// import PulseLoader from "react-spinners/PulseLoader";
import Collapse from '@mui/material/Collapse';
import { TransitionGroup } from 'react-transition-group';

const Messages = ({ chooseLogo, chatLog, profileComponent, setModel, followUpQuestions }) => {
    return ( //display-padding-without-messages-need-redoing-1
        <div className={`messages-inner ${followUpQuestions.length > 0 ? "follow-ups" : ""}`}> 
            {/* {loadingCompletion && <div className='loader'><PulseLoader color='var(--color-blue-3)'/></div>} */}
            <TransitionGroup className='transition-group'>
                {chatLog.map((chat, index) => (
                    <Collapse key={index} className='collapse'>
                        <Message key={index} chat={chat} profileComponent={profileComponent} setModel={setModel} chooseLogo={chooseLogo}/>
                    </Collapse>
                ))}
            </TransitionGroup>
        </div>
    );
}

export default Messages;