import './MainSettings.css'

import SettingSideBar from './SettingSideBar';
import General from './General';
import Integrations from './Integrations';

import Fade from '@mui/material/Fade';

import CloseIcon from '@mui/icons-material/Close';

import { useState } from 'react';

const MainSettings = ({ userDetails, setUserDetails, settingsOpen, setSettingsOpen, setModel, setReconnectError, setChatHistory, setCurrentChatID }) => {

    const [ editModeGoogle, setEditModeGoogle] = useState(false);
    const [ editModeMeta, setEditModeMeta] = useState(false);
    const [ editModeTikTok, setEditModeTikTok ] = useState(false);

    const [settingState, setSettingState] = useState("GENERAL");

    const [currentSource, setCurrentSource] = useState(null);

    const settingComponents = {
        "GENERAL": <General 
            setChatHistory={setChatHistory}
            setModel={setModel}
            setCurrentChatID={setCurrentChatID}
        />,
        "INTEGRATIONS": <Integrations
            userDetails={userDetails} 
            setUserDetails={setUserDetails} 
            setModel={setModel} 
            editModeGoogle={editModeGoogle}
            editModeMeta={editModeMeta}
            editModeTikTok={editModeTikTok}
            setEditModeGoogle={setEditModeGoogle}
            setEditModeMeta={setEditModeMeta}
            setEditModeTikTok={setEditModeTikTok}
            setReconnectError={setReconnectError}
            currentSource={currentSource}
            setCurrentSource={setCurrentSource}
        />,
    };

    const closeSettings = () => {
        setSettingsOpen(false);
        setEditModeGoogle(false); 
        setEditModeMeta(false);
        setEditModeTikTok(false);
        setSettingState("GENERAL");
        setCurrentSource(null);
    }

    return (
        <Fade in={settingsOpen}>
            <div className='popup'>
                <div className='popup-inner'>
                    <div className='content-header'>
                        <div className='settings-title'>
                            Settings
                        </div>
                        <CloseIcon onClick={() => {closeSettings()}} className='close-icon' sx={{color: 'var(--message-text-color)', cursor: 'pointer'}}/>
                    </div>
                    <div className='main-body'>
                        <SettingSideBar setSettingState={setSettingState}/>
                        {settingComponents[settingState]}
                    </div>
                    
                </div>
            </div>
        </Fade>
    )
}

export default MainSettings