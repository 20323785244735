import axios from "axios";
const updateAccountID = async (userID, accountID, setUserDetails, setEditMode, setModel, accessToken) => {
    const getUpdatePropertyIDURL = `${process.env.REACT_APP_API_URL}/update_account_id`;
    try {
      await axios.post(getUpdatePropertyIDURL, {
        user_id: userID,
        account_id: accountID,
      }, { headers: {Authorization: `Bearer ${accessToken}`} })
  
      setEditMode(false);
      setUserDetails((details) => ({...details, meta_account_id: accountID}));
      setModel({show: true, type: "success", message: "Successfully connected Account ID"})
    } catch (error) {
      console.log(error)
      let errorMessage = "";
      if (error.response && error.response.data) {
        errorMessage = error.response.data
      } else {
        errorMessage = "unknown error occurred on the server"
      }
      setModel({show: true, type: "error", message: errorMessage})
    }
  }
  
  const getMetaAuthCode = (userID, setModel, setUserDetails) => {
    const url = `https://www.facebook.com/v18.0/dialog/oauth?client_id=${process.env.REACT_APP_META_APP_ID}&redirect_uri=${process.env.REACT_APP_OWN_DOMAIN}${process.env.REACT_APP_META_CODE_RECEIVE_URI}&state=${userID}&scope=ads_read`;
    
    window.open(url, 'MetaAuth', 'width=600,height=800');
    window.addEventListener("message", (event) => {
      if (event.origin !== process.env.REACT_APP_OWN_DOMAIN) // Replace with your origin
        return;
  
        if (event.data.metaAuth) {
          if (event.data.metaAuth === "connected") {
            setModel({show: true, type: "success", message: "Successfully connected to Meta"})
            setUserDetails(details => ({...details, connected_meta: true}));
          } else {
            setModel({show: true, type: "error", message:"Unsuccessfully connected to Meta, if this persists, contact an admin."})
          }
        }
    })
  }
  
const getMetaAccessToken = async (userID, code) => {
    const metaAuthURL = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_META_CODE_RECEIVE_URI}`
    const data = {
        user_id: userID,
        code: code,
    }
    const config = {
        headers: {
        // Authorization: `Bearer ${accessToken}`,
        'X-Custom-Header': process.env.REACT_APP_META_CUSTOM_HEADER
        }
    }
    try {
        const response = await axios.post(metaAuthURL, data, config);
        var responseClient = null
        if (response.data.message === "connected") {
        responseClient = {metaAuth: "connected"}

        } else {
        responseClient = {metaAuth: "unconnected"}
        }
        window.opener.postMessage(responseClient, process.env.REACT_APP_OWN_DOMAIN); // Replace with the origin of your main window
        window.close(); // Optionally close the popup after sending the message
    } catch (error) {
        console.log(error)
    }
    // try {
    //   const response = await axios.post(metaAuthURL, data, config);
        
    //   if (response.data.message === "connected") {
    //     setModel({show: true, type: "success", message: "Successfully connected to Meta"})
    //     setUserDetails(details => ({...details, connected_meta: true}));
    //   } 
    //   // else {
    //   // TOOD unconnected for any other reason
    //   // }
    // } catch (error) {
    //   setModel({show: true, type: "error", message:"Unsuccessfully connected to Meta, if this persists, contact an admin."})
    // }
}
  
export {
    updateAccountID,
    getMetaAuthCode,
    getMetaAccessToken
}
  