import { getGoogleAccessToken } from '../../../logic/google_mec';
import './View.css'
import GoogleIcon from '@mui/icons-material/Google';

import { useContext } from 'react';
import { ServerInfo } from '../../../App';

const View = ({ userDetails, setUserDetails, setModel, setReconnectError }) => {
    const { userID, accessToken } = useContext(ServerInfo);

    const checkDisabled = () => {
        if (userDetails.connected_ga === null) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <div className='status'>
            <p>Status</p>
            <div className={`connected ${userDetails.connected_ga ? 'if-connected-green' : 'if-connected-red'}`}>
                {userDetails.connected_ga ? "Connected to Google Analytics" : "Not Connected to Google Analytics"}
            </div>

            {userDetails.connected_ga ?
                <>
                    <p>Property ID</p>
                    <div>
                        {userDetails.property_id ? userDetails.property_id : "Click 'Edit' and input"}
                    </div>
                </>
            :
                <button 
                    disabled={checkDisabled()} 
                    onClick={() => {getGoogleAccessToken(userID, setUserDetails, setModel, accessToken)}}
                    className={`google-auth ${checkDisabled() ? 'disabled' : ''}`}
                >
                    <GoogleIcon/>Connect Google Account
                </button>
            }

        </div>
    )
}

export default View