import './SearchBar.css';

import SelectOptions from './Top/SelectOptions';
import FollowUp from './Top/FollowUp';

import { useRef, useState, useEffect } from 'react';

import { SendRounded } from '@mui/icons-material';

import GoogleIcon from '@mui/icons-material/Google';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import { Collapse } from '@mui/material';

// import shopifyLogo from '../../shopify-logo-black-and-white.svg'


const SearchBar = ({ handleSubmit, userDetails, processingRequest, reconnectError, setReconnectError, currentSelect, setCurrentSelect, prompt, setPrompt, followUpQuestions }) => {

    const [options, setOptions] = useState([])

    const selectRef = useRef(false);
    
    useEffect(() => {
        // if (!selectRef.current || reconnectError !== 0) {
        setReconnectError(0)
        selectRef.current = true;
        var integrations = []
        if (userDetails.connected_ga && userDetails.property_id !== "") {
            integrations.push({
                icon: <GoogleIcon/>,
                text: "Google Analytics",
                id: "google_analytics"
            })
        }
        if (userDetails.connected_meta && userDetails.meta_account_id !== '') {
            integrations.push({
                icon: <FacebookRoundedIcon/>,
                text: "Facebook Ads",
                id: "meta_insights"
            })
        }
        if (userDetails.connected_tiktok && userDetails.tiktok_ad_id !== '') {
            integrations.push({
                icon: <svg className="tiktok-logo" width="24px" height="24px" viewBox="0 0 512 512" id="icons" xmlns="http://www.w3.org/2000/svg"><path d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z"/></svg>,
                text: "TikTok Ads",
                id: "tiktok_reporting"
            })
        }
        if (userDetails.connected_shopify) {
            integrations.push({
                icon: <svg className="tiktok-logo" fill="#000000" width="24px" height="24px" viewBox="2 2 20 20" xmlns="http://www.w3.org/2000/svg"><path d="m14.49 20.937 5.381-1.166S17.93 6.633 17.914 6.546c-.016-.086-.086-.144-.158-.144s-1.439-.101-1.439-.101-.949-.949-1.064-1.05c-.027-.029-.057-.043-.086-.058l-.677 15.744zm.446-15.772c-.016 0-.043.014-.057.014-.016 0-.217.059-.533.158-.318-.919-.879-1.768-1.871-1.768h-.086c-.289-.361-.633-.519-.936-.519-2.316 0-3.426 2.892-3.77 4.359-.892.275-1.538.476-1.613.505-.504.158-.517.172-.574.648-.057.344-1.367 10.489-1.367 10.489l10.117 1.899.69-15.785zm-2.635.704v.102c-.559.173-1.178.36-1.783.547.346-1.323.992-1.972 1.553-2.217.146.375.23.878.23 1.568zm-.92-2.2c.1 0 .201.028.303.102-.732.344-1.539 1.222-1.871 2.978a59.11 59.11 0 0 1-1.411.432c.389-1.339 1.325-3.512 2.979-3.512zm.402 7.812s-.604-.315-1.322-.315c-1.08 0-1.123.676-1.123.849 0 .921 2.418 1.28 2.418 3.453 0 1.712-1.08 2.806-2.547 2.806-1.756 0-2.648-1.094-2.648-1.094l.475-1.554s.922.792 1.697.792a.693.693 0 0 0 .721-.69c0-1.209-1.986-1.268-1.986-3.252 0-1.669 1.195-3.295 3.627-3.295.936 0 1.395.272 1.395.272l-.707 2.028zm.922-7.281c.518.06.85.648 1.064 1.311-.258.087-.547.173-.863.273v-.187c0-.561-.072-1.022-.201-1.397z"/></svg>,
                text: "Shopify",
                id: "shopify_graphql_function"
            })
        }
        setOptions(integrations);
        
        // }
    }, [userDetails])

    useEffect(() => {
        if (options.length > 0 && currentSelect == null) {
            setCurrentSelect(options[0])
        }
    }, [options]);

    
    const textAreaRef = useRef(null);
    
    const checkUserDetails = () => {
        if ( (!userDetails.connected_ga && userDetails.property_id === ""  &&
        !userDetails.connected_meta && userDetails.meta_account_id === "" &&
        !userDetails.connected_tiktok && userDetails.tiktok_ad_id === "")
        || (userDetails.tokens_left === 0)
        ) {
            return true;
        } else {
            return false;
        }
    }

    const internalHandleSubmit = () => {
        if (!processingRequest) {
            handleSubmit();
            // const text = textAreaRef.current.value;
            // if (text.trim().length > 0) {
            //     setPrompt(text);
            //     handleSubmit();
            // }
            // textAreaRef.current.value = "";
        }
    }

    if (options.length === 0) {
        return (
            ""
        )
    }
    return (
        <div className={`searchbar-parent`}>

            <Collapse in={followUpQuestions.length > 0}>
                <div className={`follow-up-questions`}>
                {
                    followUpQuestions.map(question => (
                        <FollowUp question={question} handleSubmit={handleSubmit}/>
                    ))
                }
                </div> 
            </Collapse>

            <div className={`searchbar ${checkUserDetails() ? 'disabled': ''}`}>
                <div className='top'>
                    <SelectOptions options={options} currentSelect={currentSelect} setCurrentSelect={setCurrentSelect}/>
                </div>
                <div className='bottom'>
                    <textarea className={`search-area ${checkUserDetails() ? 'disabled': ''}`}
                        disabled={checkUserDetails()}
                        // name="prompt" 
                        // id="" 
                        ref={textAreaRef}
                        rows="3"
                        placeholder='Write a question...'
                        value={prompt}
                        onChange={(event) => setPrompt(event.target.value)}
                    />
                    <button 
                        disabled={checkUserDetails()}
                        className={`submit-button ${checkUserDetails() ? 'disabled': ''}`}
                        onClick={() => internalHandleSubmit()}>
                            <SendRounded sx={{ color: 'var(--color-blue-5)' }}/>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SearchBar;