import './Completion.css';
import { Bar, Pie, Line, Scatter } from 'react-chartjs-2'
import{ Chart as ChartJS } from 'chart.js/auto'

import { Theme } from '../../../../App';
import { useContext } from 'react';

import orangeLogo from './orange_logo.svg';

const Completion = ({ chat }) => {

    const { themeMode } = useContext(Theme);

    const handleNewLines = (text) => {
        return text.split('\n').map((str, index) => <p key={index}>{str}</p>);
    }

    const chooseTheme = (type) => {
        if (type === 1) {
            return themeMode === "dark" ? "#EFF5FF": 'black'
        } else if (type === 2) {
            return themeMode === "dark" ? "#003EBB": '#B5C6E5'
        }
    }


    const displayContent = (res) => {
        if (res.type) {
            if (res.type == "graph") {
                // const jsonObject = res.data
                // const jsonObject = JSON.parse(res.data.content)
                const jsonObject = JSON.parse(res.data.function_call.arguments)
                // const rows = jsonObject.report.slice(1)
                const data = {
                    labels: jsonObject.x_axis_data.map(value => value.replace(',', '')),
                    // labels: rows.map(value => value[0]),
                    datasets: [{
                        // data: jsonObject.y_axis_data.map(value => value.replace(',', ''))
                        data: (
                            jsonObject.y_axis_data.every(item => typeof item === 'string') ? 
                            jsonObject.y_axis_data.map(value => value.replace(',', ''))
                            :
                            jsonObject.y_axis_data
                        )
                        // data: rows.map(value => value[1])
                    }]
                }
                const options = {
                    scales: {
                        x: {
                            grid: {
                                color: chooseTheme(2)
                            },
                            ticks: {
                                color: chooseTheme(1),
                                font: {
                                    family: "Poppins"
                                }
                            },
                            title: {
                                display: true,
                                text: jsonObject.x_axis_label,
                                color: chooseTheme(1),
                                font: {
                                    family: "Poppins"
                                }
                            }
                        },
                        y: {
                            grid: {
                                color: chooseTheme(2)
                            },
                            ticks: {
                                color: chooseTheme(1),
                                font: {
                                    family: "Poppins"
                                }
                            },
                            title: {
                                display: true,
                                text: jsonObject.y_axis_label,
                                color: chooseTheme(1),
                                font: {
                                    family: "Poppins"
                                }
                            }
                        },
                    },
                    plugins: {
                        legend: {
                            display: false, // Set this to false to hide the legend
                        },
                    },
                }

                return (
                    <div className='graph'>
                        {chooseGraphType(jsonObject.type_of_visual, jsonObject, {data, options})}
                    </div>
                )
            } else {
                return (
                    <div className='text-completion'>
                        {handleNewLines(res.data.content)}
                        {/* {res.data.content} */}
                    </div>
                )
            }
        }
    }


    function DataDisplayTable({ data }) {
        return (
          <table>
            <thead>
              <tr>

                <th>{data.x_axis_label}</th>
                <th>{data.y_axis_label}</th>
              </tr>
            </thead>
            <tbody>
              {data.x_axis_data.map((item, index) => (
                <tr key={index}>
                  <td>{item}</td>
                  <td>{data.y_axis_data[index]}</td>
                </tr>
              ))}

            </tbody>
          </table>
        );
    }

    const chooseGraphType = (type, jsonObject, props) => {
        if (type === 'line') {
            return <Line {...props}/>
        } else if (type === 'scatter') {
            return <Scatter {...props}/>
        } else if (type === 'pie') {
            return <Pie {...props}/>
        } else if (type === 'bar'){
            return <Bar {...props}/>
        } else { // table
            return <DataDisplayTable data={jsonObject}/>
        }
    }

    return ( // {chat.message}
        <div className='completion message-parent'>
            <div className='logo-parent'><img src={orangeLogo} className='orange-logo' alt="logo" /></div>
            {displayContent(chat)}
        </div>
    )
}

export default Completion;