const Shopify = ({userDetails}) => {

    return (
        <div className='content-semi'>

            <div className='settings-semi-title google-header'>
                Shopify
            </div>
            <div className='status'>
                <p>Status</p>
                <div className={`connected ${userDetails.connected_shopify ? 'if-connected-green' : 'if-connected-red'}`}>
                    {userDetails.connected_shopify ? "Connected to Shopify" : "Not Connected to Shopify"}
                </div>
            
                {userDetails.connected_shopify ?
                    <>
                        <p>Shop</p>
                        <div>
                            {userDetails.shopify_shop === "" ? "Click 'Edit' and input" : userDetails.shopify_shop}
                        </div>
                    </>
                :
                    ""
                }
            </div>
        </div>
    )
}
export default Shopify