import './Profile.css';

import { MoreVertOutlined } from '@mui/icons-material';

import { forwardRef } from 'react';

const Profile = forwardRef(({ user, profileComponent, onClick }, ref) => {
    return (
        <div className='profile-inner'>
            <div className='profile-icon'>
                {profileComponent}
            </div>
            <div className='name'>
                {user.nickname ? user.nickname : "Basic"}
                <p>{user.email}</p>
            </div>
            <MoreVertOutlined onClick={onClick} sx={{ fontSize: 30, cursor: 'pointer' }} ref={ref}/>
        </div>
    )
});

export default Profile;