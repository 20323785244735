import axios from "axios";

const getTikTokCode = (userID, setModel, setUserDetails) => {
    const url = `https://business-api.tiktok.com/portal/auth?app_id=${process.env.REACT_APP_TIKTOK_APP_ID}&state=${userID}&redirect_uri=${process.env.REACT_APP_OWN_DOMAIN}${process.env.REACT_APP_TIKTOK_CODE_RECIEVE_URI}`
  
    window.open(url, 'TikTokAuth', 'width=600,height=800');
    window.addEventListener('message', (event) => {
      if (event.origin !== process.env.REACT_APP_OWN_DOMAIN) // Replace with your origin
        return;
  
      // Continue with your authentication process
      if (event.data.tiktokAuth) {
        if (event.data.tiktokAuth === 'connected') {
          setModel({show: true, type: "success", message: "Successfully connected to TikTok"})
          setUserDetails(details => ({...details, connected_tiktok: true}));
        } else {
          setModel({show: true, type: "error", message:"Unsuccessfully connected to TikTok, if this persists, contact an admin."})
        }
      }
    }, false);
  }
  
const getTikTokAccessToken = async (userID, code) => {
    const url = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_TIKTOK_CODE_RECIEVE_URI}`
    const data = {
        user_id: userID,
        code: code,
    }
    const config = {
        headers: {
        // Authorization: `Bearer ${accessToken}`,
        'X-Custom-Header': process.env.REACT_APP_TIKTOK_CUSTOM_HEADER
        }
    }
    try {
        const response = await axios.post(url, data, config);
        var responseClient = null
        if (response.data.message === "connected") {
        responseClient = {tiktokAuth: "connected"}

        } else {
        responseClient = {tiktokAuth: "unconnected"}
        }
        window.opener.postMessage(responseClient, process.env.REACT_APP_OWN_DOMAIN); // Replace with the origin of your main window
        window.close(); // Optionally close the popup after sending the message
    }
    catch (error) {
        console.log(error)
    }
}
  
const updateTikTokAdId = async (userID, adID, setUserDetails, setEditMode, setModel, accessToken) => {
    const getUpdatePropertyIDURL = `${process.env.REACT_APP_API_URL}/update_ad_id`;
    try {
      await axios.post(getUpdatePropertyIDURL, {
        user_id: userID,
        account_id: adID,
      }, { headers: {Authorization: `Bearer ${accessToken}`} })
  
      setEditMode(false);
      setUserDetails((details) => ({...details, tiktok_ad_id: adID}));
      setModel({show: true, type: "success", message: "Successfully connected Account ID"})
    } catch (error) {
      console.log(error)
      let errorMessage = "";
      if (error.response && error.response.data) {
        errorMessage = error.response.data
      } else {
        errorMessage = "unknown error occurred on the server"
      }
      setModel({show: true, type: "error", message: errorMessage})
    }
}
export {
    getTikTokAccessToken,
    getTikTokCode,
    updateTikTokAdId
}