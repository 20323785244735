

import LinearProgress from '@mui/material/LinearProgress';

const Edit = ({ userDetails, setAdID, loadingSave }) => {


    return (
        <div className='status'>
            <p>Advertiser ID</p>
            <input 
                className="property-id" 
                type="text"
                // placeholder='Input your Account ID here...' 
                placeholder={userDetails.tiktok_ad_id == "" ? 'Input your Advertiser ID here...' : userDetails.tiktok_ad_id} 
                // value={accountID}
                onChange={(event) => setAdID(event.target.value)}
            />

            {loadingSave && 
            <div>
                <LinearProgress/>
            </div>
            }
        </div>
    )
}

export default Edit