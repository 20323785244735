import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { useRef } from 'react';

import logo from './logo.svg';
import shopifyLogo from './shopify-logo.svg'
import logoNoName from './logo-no-name.svg'
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';

import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';

import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom'

import CircularProgress from '@mui/material/CircularProgress';
import { getTikTokAccessToken } from './logic/tiktok';
import { getMetaAccessToken } from './logic/meta';
import { connectShopifyAccessToken } from './logic/shopify';
import Connect from './connect/Connect'

const root = ReactDOM.createRoot(document.getElementById('root'));

const LoginPage = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <div className='temp-body'>
      <img src={logo} className='temp-logo' alt="logo" />
      Temporary Beta access portal
      <button className="temp-button" onClick={() => loginWithRedirect()}>Login</button>
    </div>
  )
}

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth0();
  // const urlParams = new URLSearchParams(window.location.search);
  // const authCode = queryParams.get('code');

  if (isLoading) {
    return (
      <div className='main-app-loader'>
        <CircularProgress size={100} sx={{ color: 'var(--color-blue-3)' }}/>
      </div>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/"/>
  }
  return children
}

const TikTokAuth = () => {
  const tiktokRef = useRef(true);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("auth_code")) {
      const tiktokCode = urlParams.get("auth_code");
      const userID = urlParams.get("state");
      const fetchTikTikAccessToken = async () => {
          await getTikTokAccessToken(userID, tiktokCode);
      }
      if (tiktokRef.current) {
          tiktokRef.current = false;
          fetchTikTikAccessToken();
      }
    }
  }, [])
  return ("")
}

const MetaAuth = () => {
  const metaRef = useRef(true);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("code")) {
      const metaCode = urlParams.get("code");
      const userID = urlParams.get("state");
      const fetchMetaAccessToken = async () => {
          await getMetaAccessToken(userID, metaCode);
      }
      if (metaRef.current) {
          metaRef.current = false;
          fetchMetaAccessToken();
      }
    }
  }, [])
  return ("")
}

const ShopifyAuth = () => {
  const { loginWithRedirect, isLoading, isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const redirectURI = "http://localhost:3000/shopify_auth";
  const queryParams = new URLSearchParams(window.location.search);
  const tempID = queryParams.get('temp_id');
  if (tempID) {
    localStorage.setItem('tempID', tempID)
  }
  const [res, setRes] = useState({"connected": false})
  
  useEffect(() => {
    const fetchData = async() => {
        const accessTokenSilent = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: "openid profile email",
          }
        })
        await connectShopifyAccessToken(user.sub, accessTokenSilent, setRes, tempIDLocal);
    }
    // const fetchIfShopifyConnected = async() => {

    // }
    const tempIDLocal = localStorage.getItem("tempID")
    if (isAuthenticated && user && user.sub && tempIDLocal) {
      fetchData();
      localStorage.removeItem('tempID');
    }

    }, [user]
  )
  if (isLoading) {
    return (
      <div>Loading...</div>
    )
  }
  return (
    <div style={{display: "flex", flexDirection: "column", alignItems: "center", gap: 50, height:"50vh", justifyContent: "center"}}>
      <div style={{display: "flex", alignItems: "center"}}>
        <img src={logoNoName} style={{ width: '90px', height: 'auto' }}></img>
        <CompareArrowsIcon sx={{ color: 'gray', fontSize: 50 }}/>
        <img src={shopifyLogo} style={{ width: '100px', height: 'auto' }}></img>
      </div>
      Insight Bridge x Shopify Integration
      {user && <button className='google-auth'
      onClick={() => loginWithRedirect({ 
        redirectUri: redirectURI,
      })}>Login to connect Shopify</button>}
      {/* <div>{connect}</div> */}
      { res.connected ?
      <div style={{backgroundColor: "var(--color-success", borderRadius: 20, color: "white", paddingRight: 10, paddingLeft: 10}}>
        Connected
      </div>
      :
      <div style={{backgroundColor: "var(--color-error)", borderRadius: 20, color: "white", paddingRight: 10, paddingLeft: 10}}>
        Unconnected
      </div>
      }
      {
        res.shop ?
        <div>Shopify store: {res.shop}</div>
        :
        ""
      }

      <div style={{fontSize: 14, color: "gray"}}>Note: If you're already logged in it may connect automatically</div>
    </div>
  )
}

const router = createBrowserRouter([
  {
    path: "/app",
    element: (
      <ProtectedRoute>
        <App />
      </ProtectedRoute>
    )
  }, {
    path: "/",
    element: <LoginPage />
  },
  {
    path: "/tiktok_auth",
    element: <TikTokAuth/>
  },
  {
    path: "/meta_auth",
    element: <MetaAuth/>
  },
  {
    path: "/shopify_auth",
    element: <ShopifyAuth/>
  },
  {
    path:"/gpt-connect",
    element:<Connect/>
  }
  // {
  //   path: "/shopify_auth_response",
  //   element: <ShopifyAuthResponse/>
  // }
]);

root.render(
  <React.StrictMode>
    <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENTID}
        authorizationParams={{
          redirect_uri: process.env.REACT_APP_OWN_DOMAIN + "/app",
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: "openid profile email"
        }}
    >
      {/* <App /> */}
      <RouterProvider router={router}/>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
