import './MenuBar.css';

import Fade from '@mui/material/Fade';

import { 
    Settings, 
    Logout 
} from '@mui/icons-material';

import { useAuth0 } from "@auth0/auth0-react";

import { forwardRef } from 'react';

const MenuBar = forwardRef(({ open, setSettingsOpen },  ref ) => {

    const { logout } = useAuth0();

    const iconColor = { color: '#ffffff' }

    const menuItems = [
        {
            iconComponent: <Settings sx={iconColor}/>,
            text: "Settings",
            onClick: () => setSettingsOpen(true)
        },
        {
            iconComponent: <Logout sx={iconColor}/>,
            text: "Logout",
            onClick: () => logout({ logoutParams: { returnTo: window.location.origin } })
        }
    ]

    return (
        <Fade in={open}>
            <div className='menubar' ref={ref}>
                {
                    menuItems.map(item => (
                        <div key={item.text} onClick={item.onClick}>
                            {item.iconComponent}
                            {item.text}
                        </div>
                    ))
                }
            </div>
        </Fade>
    )
});

export default MenuBar;