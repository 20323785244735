import { useState, useEffect } from 'react';
import logo from './logo.svg';
import { useAuth0 } from '@auth0/auth0-react';
import { getUserDetails } from '../logic/controller';
import './Connect.css'
import GoogleIcon from '@mui/icons-material/Google';
// import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
// import { PropaneRounded } from '@mui/icons-material';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import { updatePropertyID, getGoogleAccessToken } from '../logic/google_mec';
import Model from '../components/Model/Model';

const redirectURI = process.env.REACT_APP_OWN_DOMAIN + "/gpt-connect";

const checkDisabled = (connect) => {
    return connect == null;
}
const containsOnlyNumbers = (inputString) => {
    // Use regular expression to check if the inputString contains only numeric characters
    return /^\d+$/.test(inputString);
}

const Connector = ({setModel, userDetails, setUserDetails, property_name, name, icon, userID, accessToken}) => {
    const [ propertyID, setPropertyID ] = useState("");
    const [ loadingSave, setLoadingSave ] = useState(false);
    // console.log(connected)
    const [connectedState, setConnectedState] = useState({css: "error", text: "Unconnected", property_id: null})
    useEffect(() => {
        if (userDetails.connected_ga) {
            setConnectedState(state => ({ ...state, css: "success", text: "Connected" }));
        }
        setPropertyID(userDetails.property_id)
    }, [userDetails]);

    const handleSavePropertyID = async () => {
        setLoadingSave(true);
        let processedPropertyID = propertyID.replace(" ", "");
        if (containsOnlyNumbers(processedPropertyID)) {
            await updatePropertyID(userID, processedPropertyID, setUserDetails, null, setModel, accessToken);
        } else {
            setModel({show: true, message: "Property ID contains only numbers", type: "warning"});
        }
        setLoadingSave(false);
    }

    return (
        <div className='connector-box'>
            <div style={{backgroundColor: `var(--color-${connectedState.css})`, borderRadius: 20, color: "white", paddingRight: 10, paddingLeft: 10, display: "inline"}}>
                {connectedState.text}
            </div>
            { // This is the 'connect 'button
                userDetails.connected_ga ?

                <div className='status'>
                    <p>{property_name}</p>
                    <input 
                        className="property-id connector-property-id" 
                        type="text"
                        placeholder={userDetails.property_id ? userDetails.property_id : 'Input your Property ID here...'} 
                        value={propertyID}
                        onChange={(event) => setPropertyID(event.target.value)}
                    />
        
                    {loadingSave && 
                    <div>
                        <LinearProgress/>
                    </div>
                    }
                    <button className='edit-google' onClick={() => handleSavePropertyID()}>
                        {
                            userDetails.property_id ?
                            "Change "
                            :
                            "Add "
                        }
                        {property_name}
                    </button>
                </div>

                :
                
                <button 
                    disabled={checkDisabled(userDetails.connected_ga)} 
                    onClick={() => {getGoogleAccessToken(userID, setUserDetails, setModel, accessToken)}}
                    className={`google-auth ${checkDisabled(userDetails.connected_ga) ? 'disabled' : ''}`}
                >
                    {icon}Connect {name} Account
                </button>
            }
        
        </div>
    )
}
const Connect = () => {
    const [ model, setModel ] = useState({show: false, message: "", type: ""});
    const { loginWithRedirect, isLoading, isAuthenticated, user, getAccessTokenSilently } = useAuth0();
    const [userDetails, setUserDetails] = useState({})
    const [loadingUserDetails, setloadingUserDetails] = useState(false);
    const [at, setat] = useState(null);
    // console.log(userDetails)
    useEffect(() => {
        (async () => {
            if (isAuthenticated && user) {
                try {
                    const token = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                        scope: "openid profile email",
                    }
                    });
                    setat(token);
                    setloadingUserDetails(true);
                    await getUserDetails(user.sub, setUserDetails, null, token)
                    setloadingUserDetails(false);
                } catch (e) {
                    console.error(e);
                }
            }
        })();
    }, [getAccessTokenSilently, isAuthenticated, user]);

    return (
        <div className='connect'>
            <Model model={model} setModel={setModel}/>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", paddingBottom: "50px"}}>
                <a href="https://www.insight-bridge.com" target="_blank" rel="noopener noreferrer">
                    <img src={logo} alt="logo" style={{width:"400px"}}/>
                </a>
                <div style={
                    {
                        padding: "8px 15px", 
                        backgroundColor: "var(--color-blue-2)", 
                        color: "var(--color-blue-7)", 
                        width: "fit-content",
                        borderRadius: "10px"
                    }
                }>
                    GPT Portal Beta
                </div>
            </div>

            <div className='sources'>

                <div className='connect-login'>
                    {isAuthenticated && user.sub ? 
                    <div className='source-box'>
                        Google Analytics 4
                        {
                            loadingUserDetails ?
                            <CircularProgress />
                            :
                            <Connector setModel={setModel} setUserDetails={setUserDetails} userDetails={userDetails} property_name="Property ID" name="Google" icon={<GoogleIcon/>} userID={user.sub} accessToken={at}/>
                        }
                        
                    </div>
                    :
                    <button className="temp-button" onClick={() => loginWithRedirect(
                        {
                            redirectUri: redirectURI
                        }
                    )}>
                        Login
                    </button>}
                </div>


                
                {/* <div className='source-box'>
                    Facebook Ads
                    <Connector connected={userDetails.connected_meta} property={userDetails.meta_account_id} property_name="Account ID" name="Facebook" icon={<FacebookRoundedIcon/>}/>
                </div>

                <div className='source-box'>
                    TikTok Ads
                    <Connector connected={userDetails.connected_tikto} property={userDetails.tiktok_ad_id} property_name="Ad Account ID" name="TikTok" icon={<svg fill='var(--setting-button-color)' width="24px" height="24px" viewBox="0 0 512 512" id="icons" xmlns="http://www.w3.org/2000/svg"><path d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z"/></svg>}/>
                </div> */}

            </div>


        </div>
    )
}

export default Connect