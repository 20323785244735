

import LinearProgress from '@mui/material/LinearProgress';



const Edit = ({ userDetails, setAccountID, loadingSave }) => {



    return (
        <div className='status'>
            <p>Account ID</p>
            <input 
                className="property-id" 
                type="text"
                placeholder={userDetails.meta_account_id === "" ? 'Input your Account ID here...' : userDetails.meta_account_id} 
                onChange={(event) => setAccountID(event.target.value)}
            />

            {loadingSave && 
            <div>
                <LinearProgress/>
            </div>
            }
        </div>
    )
}

export default Edit