import './Model.css'

import { 
    ErrorOutline, 
    CheckCircleOutline, 
    ReportProblemOutlined,
    Close 
} from '@mui/icons-material';

import Fade from '@mui/material/Fade';

const Model = ({ model, setModel }) => {

    // console.log(model);

    var selectedColor;

    const selectTypeIcon = (current) => {
        if (current.type == "success") {
            selectedColor = '#ffffff';
            return (
                <CheckCircleOutline sx={{ color: selectedColor }}/>
            )
        } else if (current.type == "error") {
            selectedColor = '#ffffff';
            return (
                <ErrorOutline sx={{ color: selectedColor }}/>
            )
        } else if (current.type == "warning") {
            selectedColor = 'var(--color-blue-9)';
            return (
                <ReportProblemOutlined sx={{ color: selectedColor }}/>
            )
        }
    }

    return (
        <Fade in={model.show}>
            <div className='model'>
                <div className='inner-model'>
                    <div className={`inner-inner-model ${model.type}`}>
                        {selectTypeIcon(model)} 
                        {model.message}
                        <div className='close-model' onClick={() => setModel((prev) => ({...prev, show: false}))}>
                            <Close sx={{ color: selectedColor }} />
                        </div>
                    </div>
                </div>
            </div>
        </Fade>
        // <Fade in={true}>
        //     <div className='model-2'>
        //         hello
        //     </div>
        // </Fade>
    )
}

export default Model