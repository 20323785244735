import axios from "axios";

const connectShopifyAccessToken = async (userID, accessToken, setRes, tempID) => {
    const url = `${process.env.REACT_APP_API_URL}/connect_shopify`
    const data = {
        user_id: userID,
        temp_id: tempID
    }
    const config = {
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    };
    try {
        const response = await axios.post(url, data,config);
        // console.log(response)
        setRes(response.data)
    }
    catch (error) {
        console.error('Error connecting Shopify:', error);
    }
}

export {
    connectShopifyAccessToken
}