import './ZoneOne.css'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import { CreateRounded } from '@mui/icons-material';
import { useEffect, useRef, useState, useContext } from 'react';
import { ServerInfo } from '../../App';

import { getQuestions } from '../../logic/controller';

import CircularProgress from '@mui/material/CircularProgress';

const CategoryOneQuestion = ({ chooseLogo, handleSubmit, question, sourceID, setPrompt }) => {
    
    return(
        <div className='cat-one-question'>
            <div className='question'>{question}</div>
            <button className='pencil' onClick={() => setPrompt(question)}><CreateRounded sx={{ color: 'var(--color-blue-4)' }}/></button>
            <button className='ask' onClick={() => handleSubmit(question, sourceID)}>Ask</button>
            {chooseLogo(sourceID)}
        </div>
    );
}

const ZoneOne = ({ chooseLogo, zone, setZone, setModel, handleSubmit, setPrompt }) => {

    const { accessToken } = useContext(ServerInfo);

    const [questions, setQuestions] = useState([]);
    const [questionsLoad, setQuestionsLoad] = useState(false);
    // const questionsRef = useRef(false);
    useEffect(() => {
        const fetchQuestions = async () => {
            setQuestionsLoad(false);
            await getQuestions(zone.id, accessToken, setModel, setQuestions)
            setQuestionsLoad(true);
        }
        fetchQuestions();
    }, [])

    return (
        <div className="zone-one">
            <div className='zone-one-top'>
                <ArrowBackIosNewIcon className="zone-one-back" onClick={() => setZone({num: 0})} sx={{color: "var(--message-text-color)"}}></ArrowBackIosNewIcon>
                <div className='zone-one-main-title'>
                    {zone.mainText}
                </div>
            </div>
            <div className='zone-one-questions'>

                {questionsLoad ? 
                questions.map(item => (
                    <CategoryOneQuestion 
                    key={item.question}
                    question={item.question}
                    sourceID={item.source_id}
                    setPrompt={setPrompt}
                    handleSubmit={handleSubmit}
                    chooseLogo={chooseLogo}
                    />
                ))
                : 
                <div className='zone-one-loader'>
                    <CircularProgress size={100} sx={{ color: 'var(--color-blue-3)' }}/>
                </div>
                }
            </div>
            
        </div>
    )
}

export default ZoneOne