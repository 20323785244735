import './Source.css'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

const Source = ({icon, text, id, onClickDiv}) => {
    return (
        <div className='source' onClick={onClickDiv}>
            <div className='source-inner'>
                {icon}
                {text}
            </div>
            <ArrowForwardIosRoundedIcon/>
        </div>
    )
}

export default Source