import axios from "axios";
const getGoogleAccessToken = (userID, setUserDetails, setModel, accessToken) => {
    /* global google */
    const google = window.google;
    
    const client = google.accounts.oauth2.initCodeClient({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      scope: process.env.REACT_APP_GOOGLE_SCOPES,
      ux_mode: 'popup',
      callback: (response) => {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_CODE_RECEIVER_URI}`, true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        // Set custom header for CRSF
        xhr.setRequestHeader('X-Custom-Header', process.env.REACT_APP_CUSTOM_HEADER);
  
        // add access token
        xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`);
  
        xhr.onload = function() {
          // console.log('Auth code response: ' + xhr.responseText);
          const responseObject = JSON.parse(xhr.responseText);
          if (responseObject.message === "connected") {
            setUserDetails((details) => ({...details, connected_ga: true}))
            setModel({show: true, type: "success", message: "Successfully connected to Google"});
          } // TODO Handle if the server doesn't authenticate
        };
        xhr.send(`code=${response.code}&user_id=${userID}`);
  
        // const data = new URLSearchParams();
      },
    });
  
    client.requestCode();
}

const updatePropertyID = async (userID, propertyID, setUserDetails, setEditMode, setModel, accessToken) => {
  const getUpdatePropertyIDURL = `${process.env.REACT_APP_API_URL}/update_property_id`;
  try {
      await axios.post(getUpdatePropertyIDURL, {
          user_id: userID,
          property_id: propertyID,
      }, { headers: {Authorization: `Bearer ${accessToken}`} })

      if (setEditMode) setEditMode(false);
      if (setUserDetails) setUserDetails((details) => ({...details, property_id: propertyID}));
      if (setModel) setModel({show: true, type: "success", message: "Successfully added Property ID"})
  } catch (error) {
      console.log(error)
      let errorMessage = "";
      if (error.response && error.response.data) {
          errorMessage = error.response.data
      } else {
          errorMessage = "unknown error occurred on the server"
      }
      if (setModel) setModel({show: true, type: "error", message: errorMessage})
  }
}


export {
    getGoogleAccessToken,
    updatePropertyID
}