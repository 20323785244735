import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import { getMetaAuthCode } from '../../../logic/meta';
import { useContext } from 'react';
import { ServerInfo } from '../../../App';

const View = ({ userDetails, setReconnectError, setModel, setUserDetails }) => {

    const { userID } = useContext(ServerInfo);
    
    const checkDisabled = () => {
        if (userDetails.connected_meta === null) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <div className='status'>
            <p>Status</p>
            <div className={`connected ${userDetails.connected_meta ? 'if-connected-green' : 'if-connected-red'}`}>
                {userDetails.connected_meta ? "Connected to Meta" : "Not Connected to Meta"}
            </div>

            {userDetails.connected_meta ?
                <>
                    <p>Account ID</p>
                    <div>
                        {userDetails.meta_account_id ? userDetails.meta_account_id : "Click 'Edit' and input"}
                    </div>
                </>
            :
                <button 
                    disabled={checkDisabled()} 
                    onClick={() => {getMetaAuthCode(userID, setModel, setUserDetails); setReconnectError(2)}}
                    className={`google-auth ${checkDisabled() ? 'disabled' : ''}`}
                >
                    <FacebookRoundedIcon/>Connect Meta Account
                </button>

            }
        </div>
    )
}

export default View