import './SideBar.css';
import logo from './../../logo.svg';
import CreateChatButton from './Independent/CreateChatButton';
import ChatHistoryButton from './Independent/ChatHistoryButton';
// import ClearChatButton from './Independent/ClearChatButton';
import TokenSummary from './TokenSummary/TokenSummary';
import Profile from './Independent/Profile';
import MenuBar from './Independent/MenuBar';
// import { DeleteRounded } from '@mui/icons-material';

import { useState, useRef, useEffect } from 'react';

import CircularProgress from '@mui/material/CircularProgress';

const SideBar = ({ user, profileComponent, chatHistory, categoryStateZero, loadChatContainer, setCurrentChatID, setChatLog, setSettingsOpen, chatHistoryLoader, userDetails, currentChatID }) => {

    const [ menuOpen, setMenuOpen ] = useState(false);

    const menuRef = useRef();
    const menuButtonRef = useRef();

    useEffect(() => {
        // Event listener for clicks on the document
        const handleOutsideClick = (event) => {
            if (!menuRef.current.contains(event.target) && !menuButtonRef.current.contains(event.target)) {
                setMenuOpen(false);
            }
        };
    
        document.addEventListener('click', handleOutsideClick);
    
        // Clean up the event listener on unmount
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    return (
        <div className='sidebar'>
         
            <img src={logo} className='logo' alt="logo" />

            <div className='create-chat'>
                <CreateChatButton text={'Create Chat'} categoryStateZero={categoryStateZero} setCurrentChatID={setCurrentChatID} setChatLog={setChatLog}/>
            </div>
            <div className='chat-history'>
                {chatHistoryLoader ? <CircularProgress/> : chatHistory.slice().reverse().map(chat => (
                    <ChatHistoryButton key={chat._id} question={chat.first_question} onClick={() => loadChatContainer(chat._id)} chatID={chat._id} currentChatID={currentChatID}/>
                ))}
            </div>
            {/* <div className='clear-chat'>
                <ClearChatButton 
                    text={'Clear All Chats'} 
                    iconComponent={<DeleteRounded sx={{ color: 'var(--color-blue-2)' }}/>}
                    onClick={null}
                />
            </div> */}
            <div className='token-summary'>
                <TokenSummary remaining={userDetails.tokens_left}/>
            </div>
            <div className='profile'>
                {/* <Fade in={menuOpen}> */}
                <MenuBar open={menuOpen} ref={menuRef} setSettingsOpen={setSettingsOpen}/>
                {/* </Fade> */}
                <Profile user={user} profileComponent={profileComponent} onClick={() => setMenuOpen(!menuOpen)} ref={menuButtonRef}/>
            </div>
        </div>
    )
}


export default SideBar;