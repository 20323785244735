import './Display.css';

import Messages from './Categories/Messages';
import CircularProgress from '@mui/material/CircularProgress';

import GoogleIcon from '@mui/icons-material/Google';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';

import ZoneOne from './ZoneOne';
import ZoneZero from './ZoneZero';

import { 
    PeopleRounded,
    BarChartRounded,
    LanguageRounded,
    ShoppingBagRounded
} from '@mui/icons-material';


const themeForIcons = {
    color: 'var(--zone-one-secton-icon)', 
    fontSize: 45, 
    margin: '15px 0px 0px 0px'
};

const allCategories = [
    {
        icon: <PeopleRounded sx={themeForIcons}/>,
        mainText: 'User Behavior and Engagement',
        subText: 'Gain insights, understand audience, optimize site based on behavior',
        id: 1
    },
    {
        icon: <BarChartRounded sx={themeForIcons}/>,
        mainText: 'Campaign and Ad Performance',
        subText: 'Analyze traffic origins, optimize channels, boost online visibility',
        id: 2
    },
    {
        icon: <LanguageRounded sx={themeForIcons}/>,
        mainText: 'Website and Platform Analytics',
        subText: 'Maximize website uptime, enhance user experience',
        id: 3
    },
    {
        icon: <ShoppingBagRounded sx={themeForIcons}/>,
        mainText: 'Sales and Product Performance',
        subText: 'Enhance product performance and user satisfaction',
        id: 4
    }
];

const Display = ({ zone, setZone, handleSubmit, chatLog, profileComponent, chatLogLoader, setPrompt, setModel, followUpQuestions }) => { // setPrompt

    const logos = {
        "google_analytics": <GoogleIcon sx={{color: "var(--message-text-color)"}}/>,
        "meta_insights": <FacebookRoundedIcon sx={{color: "var(--message-text-color)"}}/>,
        "tiktok_reporting": <svg fill='var(--message-text-color)' width="24px" height="24px" viewBox="0 0 512 512" id="icons" xmlns="http://www.w3.org/2000/svg"><path d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z"/></svg>
    }
    const chooseLogo = (sourceID) => {
        return logos[sourceID] || null
    }

    const handleZone = () => {
        if (chatLog.length >= 1) {
            return (
                <>
                {chatLogLoader ? <div className='chat-log-loader'><CircularProgress size={50}/></div> : <Messages chooseLogo={chooseLogo} chatLog={chatLog} profileComponent={profileComponent} setModel={setModel} followUpQuestions={followUpQuestions}/>}
                </>
            )
        }
        else if (zone.num === 0) {
            return (
                <ZoneZero allCategories={allCategories} setZone={setZone}></ZoneZero>
            )
        } else if (zone.num === 1) {
            return (
                <ZoneOne chooseLogo={chooseLogo} zone={zone} setZone={setZone} setModel={setModel} handleSubmit={handleSubmit} setPrompt={setPrompt}></ZoneOne>
            )
        }
    }
    return ( //className='need-to-redo-display-loader'
        <div className='display'>
            {/* {chooseDisplay()} */}
            {handleZone()}
            
        </div>
    )
}

export default Display