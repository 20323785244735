import './ZoneZero.css'

const Section = ({ handleClick, iconComponent, mainText, subText }) => {
    return (
        <button className='cat-one-button' onClick={handleClick}>
            <div className='icon'>{iconComponent}</div>
            <div className='main-text'>{mainText}</div>
            <div className='sub-text'>{subText}</div>
        </button>
    )
}

const ZoneZero = ({ allCategories, setZone }) => {

    return (
        <div className='zone-zero'>
            <div className='zero-title'>
                Welcome!
            </div>
            <div className='zero-sub-title'>
                What do you want to find out today?
            </div>
            <div className='outer-inner-graph'>
                {
                    allCategories.map(item => (
                        <Section 
                        key={item.mainText}
                        iconComponent={item.icon} 
                        mainText={item.mainText} 
                        subText={item.subText}
                        handleClick={() => setZone({mainText: item.mainText, num: 1, id: item.id})}
                        />
                    ))
                }
            </div>
        </div>
    )
}

export default ZoneZero