import './Message.css';

import Prompt from './Prompt';
import Completion from './Completion';
import Info from './Info';
// import Collapse from '@mui/material/Collapse';
// import { useState } from 'react';

const Message = ({ chat, profileComponent, setModel, chooseLogo }) => {

    // const [isIssue, setIsIssue] = useState(false); 

    const chooseMessage = (chat) => {
        if (chat.type == 'prompt') {
            return (
                <Prompt chat={chat} profileComponent={profileComponent} chooseLogo={chooseLogo}/>
            );
        } else if (chat.type == 'completion' || chat.type == 'graph') {
            return (
                <Completion chat={chat}/>
            );
        } else if (chat.type == 'info') {
            return (
                <Info chat={chat}/>
            );
        } else if (chat.type == "issue") {
            console.log(chat)
            setModel({show: true, type: "warning", message: chat.issue});
            // return (
            //     <div>
            //         Potential issue identified
            //         <button onClick={() => setIsIssue(!isIssue)}>drop down</button>
            //         <Collapse in={isIssue}>
            //             <div>
            //                 {jsonObject.issue}
            //             </div>
            //         </Collapse>
            //     </div>
            // )
        }
    }

    return (
        chooseMessage(chat)
    );
}

export default Message;