import axios from "axios";

const getFollowUpQuestions = async (userID, chatID, setFollowUpQuestions, accessToken, canFollowUp) => {
  const url = `${process.env.REACT_APP_API_URL}/get_follow_up_questions?user_id=${userID}&chat_id=${chatID}&can_follow_up=${canFollowUp}`;
  await axiosRequest(url, "get", null, setFollowUpQuestions, null, accessToken);
}

const getStoredFollowUpQuestions = async (chatID, setFollowUpQuestions, setModel, accessToken) => {
  const url = `${process.env.REACT_APP_API_URL}/get_chat_follow_up_questions?chat_id=${chatID}`;
  await axiosRequest(url, "get", null, setFollowUpQuestions, setModel, accessToken);
}

const getQuestions = async (id, accessToken, setModel, setQuestions) => {
  const url = `${process.env.REACT_APP_API_URL}/get_questions?id=${id}`
  await axiosRequest(url, 'get', null, setQuestions, setModel, accessToken);
}

const getChatHistory = async (userID, setChatHistory, setModel, accessToken) => {
  const getChatHistoryURL = `${process.env.REACT_APP_API_URL}/get_chat_first_question?user_id=${userID}`;
  await axiosRequest(getChatHistoryURL, 'get', null, setChatHistory, setModel, accessToken);
}

const getChat = async (chatID, setCurrentChatID, setChatLog, setModel, accessToken) => {
  const getChatLogURL = `${process.env.REACT_APP_API_URL}/get_chat_history?chat_id=${chatID}`;
  await axiosRequest(getChatLogURL, 'get', null, setChatLog, setModel, accessToken);
  setCurrentChatID(chatID);
}

const getUserDetails = async (userID, setUserDetails, setModel, accesToken) => {
  const getUserDetailsURL = `${process.env.REACT_APP_API_URL}/get_user_details?user_id=${userID}`;
  await axiosRequest(getUserDetailsURL, 'get', null, setUserDetails, setModel, accesToken);
}

const deleteAllChats = async (userID, setModel, accessToken, setCurrentChatID, setChatHistory) => {
  const getDeleteAllChatsURL = `${process.env.REACT_APP_API_URL}/delete_all_chats`
  try {
    const response = await axios.delete(getDeleteAllChatsURL, {
        params: { user_id: userID },
        headers: { Authorization: `Bearer ${accessToken}` }
    });
    if (response.data.message === "successful") {
      setCurrentChatID(null);
      setChatHistory([]);
      setModel({show: true, type: "success", message: "Successfully deleted all chats"});
    }
  } catch (error) {
    let errorMessage = "";
    if (error.response && error.response.data) {
      errorMessage = error.response.data
    } else {
      errorMessage = "unknown error occurred on the server"
    }
    setModel({show: true, type: "error", message: errorMessage})
  }
  // await axiosRequest(getDeleteAllChatsURL, 'delete', { chat_id: chatID }, null, setModel, accessToken)
  // delete chats on db
  // get success message
  // delete all items in the chathistory array
}

const axiosRequest = async (url, method, data=null, setData, setModel=null, accessToken) => {
  try {
    const response = await axios({url, method, data, headers: { Authorization: `Bearer ${accessToken}` }})
    setData(response.data)

  } catch (error) {
    let errorMessage = "";
    if (error.response && error.response.data) {
      errorMessage = error.response.data
    } else {
      errorMessage = "unknown error occurred on the server"
    }
    if (setModel) {
      setModel({show: true, type: "error", message: errorMessage})
    }
    
    console.log(error)
  }
}

export {
  getChatHistory,
  getChat,
  getUserDetails,
  deleteAllChats,
  getQuestions,
  getFollowUpQuestions,
  getStoredFollowUpQuestions
};
