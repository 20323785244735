import './Info.css'

const Info = ({ chat }) => {
    return (
        <div className='info'>
            {chat.data}
        </div>
    )
}

export default Info