import './SettingSideBar.css'
import SettingsIcon from '@mui/icons-material/Settings';
import HubIcon from '@mui/icons-material/Hub';
const SettingSideBar = ({ setSettingState }) => {
    return (
        <div className="setting-side-bar">
            <button className='setting-side-bar-button' onClick={() => setSettingState("GENERAL")}>
                <SettingsIcon sx={{color: 'var(--message-text-color)'}}/>
                General
            </button>
            <button className='setting-side-bar-button' onClick={() => setSettingState("INTEGRATIONS")}>
                <HubIcon sx={{color: 'var(--message-text-color)'}}/>
                Sources
            </button>
        </div>
    )
}

export default SettingSideBar
