import './Edit.css'

import LinearProgress from '@mui/material/LinearProgress';

const Edit = ({ userDetails, propertyID, setPropertyID, loadingSave }) => {

    return (
        <div className='status'>
            <p>Property ID</p>
            <input 
                className="property-id" 
                type="text"
                placeholder={userDetails.property_id == "" ? 'Input your Property ID here...' : userDetails.property_id} 
                // value={propertyID}
                onChange={(event) => setPropertyID(event.target.value)}
            />

            {loadingSave && 
            <div>
                <LinearProgress/>
            </div>
            }
        </div>
    )
}
export default Edit