import './CreateChatButton.css';

const CreateChatButton = ({ text, categoryStateZero }) => {


    return (
        <button className='light-blue-button' onClick={() => categoryStateZero()}>{text}</button>
    )
}

export default CreateChatButton;