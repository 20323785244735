import './App.css';

import SideBar from './components/SideBar/SideBar';
import Display from './components/Display/Display';
import SearchBar from './components/SearchBar/SearchBar';
import MainSettings from './components/Settings/MainSettings';
import Model from './components/Model/Model';
import CircularProgress from '@mui/material/CircularProgress';
import { 
  getChatHistory, 
  getChat, 
  getUserDetails,
  getFollowUpQuestions,
  getStoredFollowUpQuestions
} from './logic/controller';

import {
  AccountCircleOutlined,
} from '@mui/icons-material';

import { useState, useEffect, useRef, createContext } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
export const Theme = createContext({themeMode: "dark", toggleThemeMode: () => {}}); 
export const ServerInfo = createContext({userID: "", accessToken: ""})



// import { createBrowserRouter, createRoutesFromElements, Route, Navigate } from 'react-router-dom'

// import {testHistory} from './test'

function App() {

  const { user, isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();

  // console.log(user)
  // console.log(isAuthenticated)
  // console.log(isLoading)

  const profilePicture = <AccountCircleOutlined sx={{ color: 'var(--color-blue-3)', fontSize: 45 }}/>
  const profilePictureMessage = <AccountCircleOutlined sx={{ color: 'var(--color-blue-3)', fontSize: 35 }}/>

  const [ reconnectError, setReconnectError ] = useState(null);
  const [ prompt, setPrompt ] = useState('');
  const [ currentSelect, setCurrentSelect ] = useState(null);
  const [ chatLog, setChatLog ] = useState([
    // {
    //   "type": "graph",
    //   "source_id": "google_analytics",
    //   'data': {
    //     'role': 'assistant', 
    //     'content': null, 
    //     'function_call': {
    //       'name': 'visualize_data', 
    //       'arguments': '{"x_axis_data":["2023-11-24","2023-11-25","2023-11-26","2023-11-27","2023-11-28","2023-11-29","2023-11-30"],"x_axis_label":"Date","y_axis_data":[104818.34,38765.95,33777.61,72047.43,37358.7,31000.28,16439.54],"y_axis_label":"Total Revenue","type_of_visual":"bar"}'
    //     }
    //   }
    // }
  ]);
  const [ followUpQuestions, setFollowUpQuestions] = useState([]); 

  const [ chatHistory, setChatHistory ] = useState([]);
  const [ currentChatID, setCurrentChatID ] = useState(null);
  const [ processingRequest, setProcessingRequest ] = useState(false);

  const [ userDetails, setUserDetails ] = useState(
    {
      // connected_ga: true, 
      // property_id: '0', 
      
      // connected_meta: true,
      // meta_account_id: '0',

      // connected_tiktok: true,
      // tiktok_ad_id: '0',
      
      // tokens_left: 1,
    }
  );
  const [loadedUserDetails, setLoadedUserDetails] = useState(false);

  const [ model, setModel ] = useState({show: false, message: "", type: ""});

  const [ accessToken, setAccessToken ] = useState(null);

  const [ chatHistoryLoader, setChatHistoryLoader ] = useState(false);
  const [ chatLogLoader, setChatLogLoader ] = useState(false);

  const [zone, setZone] = useState({num: 0})
  // console.log(zone)
  const fetchDataLock = useRef(true);
  const userDetailsTokenLock = useRef(true);


  const getUserDetailsWrapper = async (accessToken) => {
    // setLoadedUserDetails(false);
    await getUserDetails(user.sub, setUserDetails, setModel, accessToken);
    setLoadedUserDetails(true);
  }

  useEffect(() => {
      const fetchData = async() => {
        // try {
          const accessTokenSilent = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE,
              scope: "openid profile email",
            }
          })

          setAccessToken(accessTokenSilent);
          setChatHistoryLoader(true);
          await getChatHistory(user.sub, setChatHistory, setModel, accessTokenSilent);
          await getUserDetailsWrapper(accessTokenSilent);
          setChatHistoryLoader(false);
        // } catch (error) {
        //   setModel({show: true, type: "error", message: error});
        // }
      }
      
      if (fetchDataLock.current && isAuthenticated && user.sub ) {
        fetchDataLock.current = false;
        fetchData();
      }
      // setModel({show: true, message: "success", type: "success"});

    }, [isAuthenticated]
  )
  
  // the property id when a user is created needs to 0
  useEffect(() => {
    // if (userDetailsLock.current &&
    if (loadedUserDetails &&
      (
        (!userDetails.connected_ga || userDetails.property_id === null) &&
        (!userDetails.connected_meta || userDetails.meta_account_id === null) &&
        (!userDetails.connected_tiktok || userDetails.tiktok_ad_id === null)
      )
    ) {
      setModel({show: true, type: "warning", message: "Connect a Source in settings"});
    }

    if (userDetailsTokenLock.current && userDetails.tokens_left === 0) {
      userDetailsTokenLock.current = false;
      setModel({show: true, type: "warning", message: "You're out of question tokens this month."})
    }
  }, [userDetails])

  const loadChatContainer = async (chatID) => {
    // setChatLogLoader(true);
    await getChat(chatID, setCurrentChatID, setChatLog, setModel, accessToken);
    await getStoredFollowUpQuestions(chatID, setFollowUpQuestions, setModel, accessToken);
    setChatLogLoader(false);
  }
  
  const handleSubmit = async (newPrompt = null, select = null) => {
    setFollowUpQuestions([]);
    setProcessingRequest(true);
    let canFollowUp = false;

    const effectivePrompt = newPrompt !== null ? newPrompt : prompt;
    const effectiveSelect = select !== null ? select : currentSelect.id;

    if (effectivePrompt.trim().length === 0) {
      return;
    }
    setPrompt('');

    // add prompts to the chat log {type: "prompt", data: {content: prompt}}
    setChatLog((chats) => [...chats, {type: "prompt", source_id: effectiveSelect, data: {content: effectivePrompt}}]);

    // make web socket hook
    const ws = new WebSocket(process.env.REACT_APP_API_WEBSOCKET_URL); // the server will close this web socket after it deals with this request

    // send relevent to web socket
    ws.onopen = function(event) {
      const data = {
        token: accessToken,
        user_id: user.sub,
        chat_id: currentChatID,
        prompt: effectivePrompt,
        source_id: effectiveSelect
      }
      ws.send(JSON.stringify(data));
    }

    // websocket on message receive -> adds this to the chat log
    ws.onmessage = async function(event) {
      const eventData = JSON.parse(event.data);

      if (eventData.error) {
        console.log(eventData);
        if (eventData.error_code) {
          setReconnectError(eventData.error_code);
        }
        setModel({show: true, type: "error", message: eventData.error});
        setChatLog((chats) => [...chats.filter(item => item.type !== 'info'), eventData]);
        await getUserDetailsWrapper(accessToken);
      }
      
      if (eventData.type === "completion") { // now the server has sent its "final" message so can delete the info messages from chatLog
        setChatLog((chats) => [...chats.filter(item => item.type !== 'info'), eventData]);
      } else {
        setChatLog((chats) => [...chats, eventData]);
      }

      if ("chat_id" in eventData && "tokens_left" in eventData && 'follow_up' in eventData) {

        if (currentChatID === null) { // add the chat to the sidebar to the chat history state
          setChatHistory((history) => [...history, {_id: eventData.chat_id, first_question: effectivePrompt}]);
        }

        setCurrentChatID(eventData.chat_id);

        //update token values
        setUserDetails(details => ({...details, tokens_left: eventData.tokens_left}));

        canFollowUp = eventData.follow_up;

        await getFollowUpQuestions(user.sub, eventData.chat_id, setFollowUpQuestions, accessToken, canFollowUp);

      }
    }

    ws.onclose = async function(event) {
      setProcessingRequest(false);
    }
  }

  const categoryStateZero = () => {
    setCurrentChatID(null);
    setChatLog([]);
    setPrompt('');
    setZone({num: 0});
    setFollowUpQuestions([]);
  }

  // handles settings menu
  const [ settingsOpen, setSettingsOpen ] = useState(false);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const settingsOpenURI = urlParams.get('editModeMeta');
    if (settingsOpenURI === 'true') {
      setSettingsOpen(true)
    }
  }, [])
  // console.log(user)


  // } else {

  // const router = createBrowserRouter(
  //   createRoutesFromElements(
  //     <Route path="/">
  //       <Route>

  //       </Route>
  //     </Route>
  //   )
  // )
  // const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches
  const [ themeMode, setThemeMode ] = useState(localStorage.getItem("theme") || "light");
  const toggleThemeMode = () => {
    setThemeMode((curr) => (curr === "dark"? "light" : "dark"))
  }
  useEffect(() => {
    const className = "dark";
    const element = window.document.body;
    if (themeMode === "dark") {
      element.classList.add(className)
    } else {
      element.classList.remove(className)
    }
    localStorage.setItem("theme", themeMode);
  }, [themeMode])
  useEffect(() => {
    if (!localStorage.getItem("theme")) {
      localStorage.setItem("theme", "light");
    }
  }, [])


  

  if (isLoading || !loadedUserDetails) {
    return (
      <div className='main-app-loader'>
        <CircularProgress size={100} sx={{ color: 'var(--color-blue-3)' }}/>
      </div>
    );
  }

  
  return (
    <Theme.Provider value={{ themeMode, toggleThemeMode }}> 
    <ServerInfo.Provider value={{ userID: user.sub, accessToken: accessToken }}>
    <div className='app'>
      <Model model={model} setModel={setModel}/>

      {(user.sub && accessToken != null && loadedUserDetails) ? <MainSettings
        userDetails={userDetails}
        setUserDetails={setUserDetails}
        userID={user.sub}
        settingsOpen={settingsOpen}
        setSettingsOpen={setSettingsOpen}
        setModel={setModel}
        setReconnectError={setReconnectError}
        setChatHistory={setChatHistory}
        setCurrentChatID={setCurrentChatID}
      /> : ""}
      {(user) ? <SideBar 
        user={user}
        profileComponent={profilePicture}
        chatHistory={chatHistory}
        categoryStateZero={categoryStateZero}
        loadChatContainer={loadChatContainer}
        setCurrentChatID={setCurrentChatID}
        setChatLog={setChatLog}
        setSettingsOpen={setSettingsOpen}
        chatHistoryLoader={chatHistoryLoader}
        userDetails={userDetails}
        currentChatID={currentChatID}
      />  : ""}

      <Display
        userID={user.sub}
        zone={zone}
        setZone={setZone}
        handleSubmit={handleSubmit} 
        setPrompt={setPrompt} 
        chatLog={chatLog} 
        profileComponent={profilePictureMessage} 
        chatLogLoader={chatLogLoader}
        setModel={setModel}
        followUpQuestions={followUpQuestions}
      />
      
      {loadedUserDetails ? <SearchBar 
        prompt={prompt} 
        setPrompt={setPrompt} 
        handleSubmit={handleSubmit}
        userDetails={userDetails}
        processingRequest={processingRequest}
        reconnectError={reconnectError}
        setReconnectError={setReconnectError}
        currentSelect={currentSelect}
        setCurrentSelect={setCurrentSelect}
        followUpQuestions={followUpQuestions}
      />
      :
      ""}

    </div>
    </ServerInfo.Provider>
    </Theme.Provider>
    
  );
}

export default App;
